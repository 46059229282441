import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalContext } from "../ModalContextWrapper";
import { mediaIcons } from "../../data";
import "./Media.css";

export default function Media(props) {
  const { showModal, setShowModal } = useContext(ModalContext);

  const handleIconClick = (e) => {
    if (props.isLast) {
      e.preventDefault();
      setShowModal(true);
    }
  };
  // hello world //

  return (
    <div className="media">
      <div>
        <div
          className="box"
          style={{ backgroundImage: `url(${props.item.backgroundImage})` }}
        >
          <div className="overlay">
            <div className="media-container-title-container">
              <h3 className="media-container-title">{props.item.title}</h3>
              <p className="media-container-text">{props.item.description}</p>
              <div className="media-container-icon">
                <a href={`${props.item.link1}`}>
                  {typeof mediaIcons[props.item.leftIcon] !== "string" ? (
                    <FontAwesomeIcon
                      icon={mediaIcons[props.item.leftIcon]}
                      className="icon-folder-iamge-fa"
                    />
                  ) : (
                    <img
                      src={mediaIcons[props.item.leftIcon]}
                      className="icon-iamge"
                    />
                  )}
                </a>

                <a
                  href={`${props.item.link2}`}
                  onClick={props.isLast ? handleIconClick : null}
                >
                  {typeof mediaIcons[props.item.rightIcon] !== "string" ? (
                    <FontAwesomeIcon
                      icon={mediaIcons[props.item.rightIcon]}
                      className="icon-iamge-fa"
                    />
                  ) : (
                    <img
                      src={mediaIcons[props.item.rightIcon]}
                      className="icon-iamge"
                    />
                  )}
                </a>
              </div>
            </div>
          </div>
          <div className="icon">
            <FontAwesomeIcon
              icon={mediaIcons[props.item.overlayIcon]}
              className="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
