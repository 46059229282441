import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-solid-svg-icons";

export const mediaList = [
  {
    id: 1,
    title: "Media",
    description: "Other media I use",
    link1: "https://www.instagram.com/hunterr.fitness/",
    link2:
      "https://discord.com/channels/1010299096738840627/1010299146441347162",
    leftIcon: "instagram",
    rightIcon: "discord",
    overlayIcon: "search",
    backgroundImage:
      "https://res.cloudinary.com/dav8yugzm/image/upload/v1695926634/lofi-cozy-house-rainy-night-thumb1_eugwlx.jpg",
  },
  {
    id: 2,
    title: "Business",
    description: "Contact me on LinkedIn",
    link1: "https://www.linkedin.com/in/hunter-jerochim-0b0061259/",
    link2: "https://github.com/HunterJerochim",
    leftIcon: "linkedIn",
    rightIcon: "github",
    overlayIcon: "message",
    backgroundImage:
      "https://res.cloudinary.com/dav8yugzm/image/upload/v1695927860/dFGhL52_iipjyn.webp",
  },
  {
    id: 3,
    title: "Resume",
    description: "Contact me on LinkedIn",
    link1: "https://www.linkedin.com/in/hunter-jerochim-0b0061259/",
    link2: "https://github.com/HunterJerochim",
    leftIcon: "folder",
    rightIcon: "addresscard",
    overlayIcon: "envelope",
    backgroundImage:
      "https://res.cloudinary.com/dav8yugzm/image/upload/v1695928666/maxresdefault_smta8y.jpg",
  },
];

export const mediaIcons = {
  instagram:
    "https://res.cloudinary.com/dav8yugzm/image/upload/v1670353321/2504645431599781003-128_vbzye6.png",
  discord:
    "https://res.cloudinary.com/dav8yugzm/image/upload/v1670821807/15127123631579517835-128_gir7pw.png",
  search: faSearch,
  linkedIn:
    "https://res.cloudinary.com/dav8yugzm/image/upload/v1670821946/16090541531530099327-128_eftzil.png",
  github:
    "https://res.cloudinary.com/dav8yugzm/image/upload/v1670822030/4422549621553664904-128_rabgxg.png",
  message: faMessage,
  envelope: faEnvelope,
  addresscard: faAddressCard,
  folder: faFolder,
};

export const mediaListMobile = [
  {
    name: "instagram",
    url: "https://res.cloudinary.com/dav8yugzm/image/upload/v1670353321/2504645431599781003-128_vbzye6.png",
    href: "https://www.instagram.com/hunterr.fitness/",
  },
  {
    name: "discord",
    url: "https://res.cloudinary.com/dav8yugzm/image/upload/v1670821807/15127123631579517835-128_gir7pw.png",
    href: "https://discord.com/channels/1010299096738840627/1010299146441347162",
  },
  {
    name: "linkedIn",
    url: "https://res.cloudinary.com/dav8yugzm/image/upload/v1670821946/16090541531530099327-128_eftzil.png",
    href: "https://www.linkedin.com/in/hunter-jerochim-0b0061259/",
  },
  {
    name: "github",
    url: "https://res.cloudinary.com/dav8yugzm/image/upload/v1670822030/4422549621553664904-128_rabgxg.png",
    href: "https://github.com/HunterJerochim",
  },
];
