import React, { useState, createContext } from "react";
import Modal from "./Modal/Modal";

export const ModalContext = createContext({});

const ModalConextWrapper = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  console.log(showModal);
  return (
    <>
      <ModalContext.Provider value={{ showModal, setShowModal }}>
        {children}
      </ModalContext.Provider>
      <Modal setShowModal={setShowModal} showModal={showModal} />
    </>
  );
};

export default ModalConextWrapper;
