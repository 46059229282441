import React, { useRef, useEffect } from "react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./Modal.css";

export default function Modal(props) {
  const linkedIn = "https://www.linkedin.com/in/hunter-jerochim-0b0061259/";
  const gitHub = "https://github.com/HunterJerochim";
  const email = "hunter.jerochim@gmail.com";

  const [currentLinkCopied, setCurrentLinkCopied] = React.useState("");
  const timeout = useRef();

  console.log("I mounted!");

  useEffect(() => {
    if (!currentLinkCopied) return;

    if (timeout.current) clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setCurrentLinkCopied("");
    }, 2000);
  }, [currentLinkCopied]);

  function onClose() {
    props.setShowModal(false);
  }

  const handleCopy = (name, text) => {
    setCurrentLinkCopied(name);
    navigator.clipboard.writeText(text);
  };

  return (
    <div className={`contact-menu-container${props.showModal ? " show" : ""}`}>
      <div className="contact-menu">
        <FontAwesomeIcon
          icon={faCircleXmark}
          className="close-icon"
          onClick={onClose}
        />
        <h1 className="contact-title">Contact Me!</h1>
        <h3 className="description-contact">
          Reach out via email, or connect with me on LinkedIn and GitHub—I'm on
          the hunt for new job opportunities and would love to chat about
          potential collaborations or positions in the tech field!
        </h3>
        <div className="contact-container">
          <div className="contact-links-container">
            <div className="email-contact-container">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="email-contact-icon"
              />
              <h1 className="email-contact">Email</h1>
              <FontAwesomeIcon
                icon={faCopy}
                className={
                  currentLinkCopied === "email" ? "link-active" : "copy-icon"
                }
                onClick={() => handleCopy("email", email)}
              />
            </div>
            <div className="link1-container">
              <FontAwesomeIcon icon={faLinkedin} className="link1-icon" />
              <a
                className="linkedin-contact"
                href="https://www.linkedin.com/in/hunter-jerochim-0b0061259"
              >
                LinkedIn
              </a>
              <FontAwesomeIcon
                icon={faCopy}
                className={
                  currentLinkCopied === "linkedIn" ? "link-active" : "copy-icon"
                }
                onClick={() => handleCopy("linkedIn", linkedIn)}
              />
            </div>
            <div className="link2-container">
              <FontAwesomeIcon icon={faGithub} className="link2-icon" />
              <a
                className="github-contact"
                href="https://github.com/HunterJerochim"
              >
                GitHub
              </a>
              <FontAwesomeIcon
                icon={faCopy}
                className={
                  currentLinkCopied === "gitHub" ? "link-active" : "copy-icon"
                }
                onClick={() => handleCopy("gitHub", gitHub)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
