import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Mediamobile.css";
import { ModalContext } from "../ModalContextWrapper";
import { mediaList, mediaListMobile } from "../../data";

export default function Mediamobile() {
  const { showModal, setShowModal } = useContext(ModalContext);

  const isLast = mediaList.length - 1;

  const handleIconClick = (e) => {
    if (isLast) {
      e.preventDefault();
      setShowModal(true);
    }
  };

  return (
    <div className="icons-container">
      {mediaListMobile.map((item) => (
        <a href={item.href} className="media-mobile-icon-buttons">
          <img src={item.url} className="icon-image-mobile" />
        </a>
      ))}
    </div>
  );
}
