import React, { useState } from "react";
import "./Projects.css";
import Pokedex from "../Pokedex";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowRight,
  faCircleArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

const projects = [
  {
    title: "Pokedex",
    description:
      "A user-friendly Pokédex application that allows users to effortlessly search and retrieve information about their favorite 1st gen Pokémon.",
    link: "https://github.com/HunterJerochim/Pokedex",
  },
  {
    title: "Chrome-Extension",
    description:
      "A versatile Chrome extension equipped with widgets for real-time weather updates, link management, Kanye West quote generation, current time display, and daily cryptocurrency price insights, all powered by various APIs.",
    link: "https://github.com/HunterJerochim/Chrome-Extension",
  },
  {
    title: "Movie-Watchlist",
    description:
      "An intuitive movie search application that provides users with movie details, ratings, and photos, enabling them to curate and manage their personal movie-watchlist",
    link: "https://github.com/HunterJerochim/Movie-watchlist2",
  },
  {
    title: "Quizzical",
    description:
      "A React-based quiz app using React routes to deliver a multi-page test with 10 random questions from the Open Trivia API, concluding with a final score and answer review.",
    link: "https://github.com/HunterJerochim/Quizzical",
  },
  {
    title: "Discord",
    description: "Coming soon",
    link: "https://github.com/HunterJerochim/Quizzical",
  },
  {
    title: "Tenzies",
    description:
      "A Tenzies game where users aim for the high score in terms of best time and fewest rounds, with local saving of achievements and a celebratory confetti shower upon victory.",
    link: "https://github.com/HunterJerochim/Tenzies",
  },
];

/*const projects = [
  {
    title: "Pokedex",
    description:
      "Find info on your favorite pokemon. Powered by https://pokeapi.co.",
    element: <Pokedex />,
  },
  {
    title: "Pokedex 2",
    description:
      "Find info on your favorite pokemon. Powered by https://pokeapi.co.",
    element: <Pokedex />,
  },
  {
    title: "Pokedex 3",
    description:
      "Find info on your favorite pokemon. Powered by https://pokeapi.co.",
    element: <Pokedex />,
  },
]; */

export default function Projects() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerHovered, setContainerHovered] = useState(false);
  /*const currentElement = projects[currentIndex].element;
  const currentTitle = projects[currentIndex].title;
  const currentDescription = projects[currentIndex].description; */

  const handleLeftClick = () => {
    if (currentIndex === 0) return;

    setCurrentIndex(currentIndex - 1);
  };

  /*const handleRightClick = () => {
    if (currentIndex === projects.length - 1) return;

    setCurrentIndex(currentIndex + 1);
  }; */

  return (
    <div
      className="projects"
      onMouseEnter={() => setContainerHovered(true)}
      onMouseLeave={() => setContainerHovered(false)}
      id="project-container-scroll"
    >
      <div className="projects-container">
        <div className="header-container">
          <h1 className="title-text">Projects</h1>
          <div className="projects-header-line"></div>
        </div>
        <div className="projects-description-container">
          <p className="projects-description">
            Here are some of my projects that have helped me become a better
            developer. From building an interactive Pokedex to creating a
            responsive landing page, I have learned new skills and techniques
            that have helped me grow in my career. These projects have pushed me
            to think critically and solve problems, making me a more confident
            and capable developer.
          </p>
        </div>
        <div className="projects-contents">
          {projects.map((item, index) => (
            <div key={`project-${index}`} className="content-box">
              <div>
                <h1 className="content-title">{item.title}</h1>
                <p className="content-description">{item.description}</p>
              </div>
              <a href={item.link}>
                <FontAwesomeIcon icon={faGithub} className="content-icon" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// this goes between "{currentIndex !== 0 && <LeftArrow onClick={handleLeftClick} containerHovered={containerHovered} />}" and <h1> //
// {(currentIndex !== projects.length - 1) && <RightArrow onClick={handleRightClick} containerHovered={containerHovered} />} //
/*
<h1 className="projects-title-text">{currentTitle}</h1>
          <p className="projects-description-text">{currentDescription}</p>
          {currentElement} */

const LeftArrow = ({ onClick, containerHovered }) => (
  <div
    style={{
      position: "absolute",
      left: "8px",
      top: "50%",
      margin: "auto 0",
      zIndex: "2",
      transform: "translate(0,-50%)",
      height: "24px",
      width: "24px",
      cursor: "pointer",
      opacity: containerHovered ? "1" : "0",
      transition: "opacity 150ms ease",
    }}
  >
    <FontAwesomeIcon
      icon={faCircleArrowLeft}
      onClick={onClick}
      style={{ height: "24px", width: "24px" }}
    />
  </div>
);

const RightArrow = ({ onClick, containerHovered }) => (
  <div
    style={{
      position: "absolute",
      right: "8px",
      top: "50%",
      margin: "auto 0",
      zIndex: "2",
      transform: "translate(0,-50%)",
      height: "24px",
      width: "24px",
      cursor: "pointer",
      opacity: containerHovered ? "1" : "0",
      transition: "opacity 150ms ease",
    }}
  >
    <FontAwesomeIcon
      icon={faCircleArrowRight}
      onClick={onClick}
      style={{ height: "24px", width: "24px" }}
    />
  </div>
);
