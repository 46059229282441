import React, { useRef } from "react";
import config from "../../config";
import "./About.css";

export default function About() {
  return (
    <div className="about" id="about-container-scroll">
      <div className="main-container">
        <div className="about-header">
          <h1 className="about-title">About Me</h1>
          <div className="about-header-line" />
        </div>
        <div className="about-description-row">
          <img src={config.headerImageUrl} className="about-img" />
          <div className="about-info-container">
            <p className="about-bio">
              My name is Hunter Jerochim, I am a Junior Software Engineer based
              in Washington state, USA. I am very passionate and dedicated to
              becoming a better developer. With just 3 months of experience and
              learning the skills of a Software Engineer I feel that I have
              already come so far!
            </p>
            <div className="about-stats">
              <div className="about-stats-row">
                <div className="about-stats-text-content">
                  <h2 className="about-stats-text">Name:</h2>
                  <h2 className="about-stats-text">Hunter Jerochim</h2>
                </div>
                <div className="about-stats-text-content">
                  <h2 className="about-stats-text">Age:</h2>
                  <h2 className="about-stats-text">27</h2>
                </div>
              </div>
              <div className="about-stats-row">
                <div className="about-stats-text-content">
                  <h2 className="about-stats-text">Discord:</h2>
                  <h2 className="about-stats-text">Hunter#2669</h2>
                </div>
                <div className="about-stats-text-content">
                  <h2 className="about-stats-text">Email:</h2>
                  <h2 className="about-stats-text">
                    hunter.jerochim@gmail.com
                  </h2>
                </div>
              </div>
              <div className="about-stats-row">
                <div className="about-stats-text-content">
                  <h2 className="about-stats-text">Phone:</h2>
                  <h2 className="about-stats-text">+1 425-877-0948</h2>
                </div>
                <div className="about-stats-text-content">
                  <h2 className="about-stats-text">Github:</h2>
                  <h2 className="about-stats-text">HunterJerochim</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
