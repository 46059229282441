import React, { useRef, useEffect, useContext } from "react";
import config from "../../config";

import "./Navbar.css";
import { ModalContext } from "../ModalContextWrapper";

export default function Navbar() {
  const { showModal, setShowModal } = useContext(ModalContext);

  function handleHeaderClick(id) {
    const element = document.getElementById(id);

    if (element?.scrollIntoView) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  /*    const handleHeaderClick = id => {
      const element = document.getElementById(id)

      if (element?.scrollIntoView) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }*/

  return (
    <nav className="navbar-container">
      <div className="header-description">
        <span className="header-title">Hello! My name is</span>
        <h2 className="header-title1">Hunter Jerochim</h2>
        <h3 className="header-subtitle">I am a Junior Software Developer</h3>
      </div>
      <div className="header">
        <h1
          className="header-element"
          onClick={() => handleHeaderClick("about-container-scroll")}
        >
          About
        </h1>
        <h1 className="header-element">Resume</h1>
        <h1
          className="header-element"
          onClick={() => handleHeaderClick("project-container-scroll")}
        >
          Projects
        </h1>
        <div
          className="header-contact-button"
          onClick={() => setShowModal(true)}
        >
          Contact Me
        </div>
      </div>
      <div className="navbar-container-gradient" />
    </nav>
  );
}
