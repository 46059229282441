import ModalConextWrapper from "./components/ModalContextWrapper";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About.js";
import Media from "./components/Media/Media";
import Projects from "./components/Projects/Projects";
import Mediamobile from "./components/Media Mobile/Mediamobile";
import { mediaList } from "./data";

function App() {
  const medias = mediaList.map((item, index) => {
    return (
      <Media key={item.id} item={item} isLast={index === mediaList.length - 1} />
    );
  });

  return (
    <ModalConextWrapper>
      <div className="main">
        <Navbar />
        <div className="content">
          <About />
          <Projects />
        </div>
        <section className="media-list">{medias}</section>
        <Mediamobile />
      </div>
    </ModalConextWrapper>
  );
}

export default App;
