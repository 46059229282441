import React from "react"
import Search from './Search/search'
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons';


function Pokedex() {

  const [pokeData, setPokeData] = React.useState({})
  const name = pokeData.name
  const img = pokeData.sprites?.other["official-artwork"]?.front_default
  const about = {
    type: pokeData.types?.map(type => type.type.name),
    height: pokeData.height,
    weight: pokeData.weight,
    abilities: pokeData.abilities?.map(ability => ability.ability.name),
  }

  const [selectedStat, setSelectedStat] = React.useState('')

  function toggleAbout() {
    // if there is no data, do nothing
    if (!pokeData?.name) return

    if (selectedStat === 'about') {
      setSelectedStat('')
    } else {
      setSelectedStat('about')
    }
  }

  function toggleStats() {
    // if there is no data, do nothing
    if (!pokeData?.name) return

    if (selectedStat === 'stats') {
      setSelectedStat('')
    } else {
      setSelectedStat('stats')
    }
  }

  function toggleMoves() {
    // if there is no data, do nothing
    if (!pokeData?.name) return

    if (selectedStat === 'moves') {
      setSelectedStat('')
    } else {
      setSelectedStat('moves')
    }
  }

  async function getPokeData(value) {
    const response = await fetch(`https://pokeapi.co/api/v2/pokemon/${value}`)
    const responseJSON = await response.json()
    setPokeData(responseJSON)

    if (responseJSON?.name) {
      setSelectedStat('about')
    }
  }

  return (
    <div className='pokemon-class-container'>
      <Search handleSubmit={getPokeData} hasPokeData={!!name} setPokeData={setPokeData} setSelectedStat={setSelectedStat} />
      <div className="container">
        <div className="image--container">
          {name ? <h1 className="pokemon--name">{name.charAt(0).toUpperCase() + name.slice(1)}</h1> : <h1 className="pokemon--name"></h1>}
          {img ? <img className="pokemon--image" src={img}/> : <FontAwesomeIcon icon={ faQuestion } className="image--unknown" />}
        </div>

        <div className="button--container">
          <div className="button--stats--container">
              <button className="button--stats" onClick={toggleAbout}>
                About
              </button>
              <button className="button--stats" onClick={toggleStats}>
                Base Stats
              </button>
              <button className="button--stats" onClick={toggleMoves}>
                Moves
              </button>
          </div>
          <div className='stats-info-container'>
            {selectedStat === 'about' && (
                <div className="about--container--conditional">
                  <p style={{ color: 'rgba(255, 255, 255, 0.6)'}}><b style={{ color: 'white' }}>Type:</b> {about?.type?.join?.(", ")}</p>
                  <p style={{ color: 'rgba(255, 255, 255, 0.6)'}}><b style={{ color: 'white' }}>Height:</b> {about?.height}</p>
                  <p style={{ color: 'rgba(255, 255, 255, 0.6)'}}><b style={{ color: 'white' }}>Weight:</b> {about?.weight}</p>
                  <p style={{ color: 'rgba(255, 255, 255, 0.6)'}}><b style={{ color: 'white' }}>Abilities:</b> {about?.abilities?.join?.(", ")}</p>
                </div>
              )}
            {selectedStat === 'stats' && (
                <div className="about--container--conditional">
                  <p>Stats</p>
                </div>)}
            {!selectedStat && !pokeData?.name &&
              <div className="about--container--conditional">
              <p>Please enter a Pokémon name</p>
            </div>
            }
            {selectedStat === 'moves' && (
              <div className="about--container--conditional">
                <p>Moves</p>
              </div>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pokedex;

